<template>
  <div class="max">
    <div class="login-main">
    <div class="login-left">
      <div class="login-title">
        开具发票
      </div>
      <div class="henxian"></div>
      <div class="bufu" v-if="kai == 0">
        您没有可开票订单，如有疑问，请添加微信客服keyanpro。
      </div>
      
      <div class="bufu" v-if="kai == 1">
        您本次可开票金额为<span style="color: #FF0000;">{{money}}</span>元，请填写完整开票信息。
      </div>
      <div class="form">
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">发票抬头</div>
              <div class="redstar">
                *
              </div>
              <div class="errmessage">
                {{fapiao1errmessage}}
              </div>
            </label>
          </div>
          <div class="z_input">
            <div class="img"><img src="@/components/template/img/fp1.png" /></div>
          <input
            type="text"
            v-model="fapiao1"
            v-if="kai == 1"
          >
           <input
            type="text"
            v-if="kai == 0"
            readonly
          >
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">机构代码</div>
              <div class="redstar">
                *
              </div>
              <div class="errmessage">
                {{fapiao2errmessage}}
              </div>
            </label>
          </div>
          <div class="z_input">
            <div class="img"><img src="@/components/template/img/fp2.png" /></div>
          <input
            type="text"
            v-model="fapiao2"
            v-if="kai == 1"
          >
           <input
            type="text"
            v-if="kai == 0"
            readonly
          >
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">邮箱地址</div>
              <div class="redstar">
                *
              </div>
              <div class="errmessage">
                {{fapiao3errmessage}}
              </div>
            </label>
          </div>
          <div class="z_input">
            <div class="img"><img src="@/components/template/img/fp3.png" /></div>
          <input
            type="text"
            v-model="fapiao3"
            v-if="kai == 1"
          >
           <input
            type="text"
            v-if="kai == 0"
            readonly
          >
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <label for="">
              <div class="text">备注</div>
            </label>
          </div>
          <div class="z_input">
            <div class="img"><img src="@/components/template/img/fp4.png" /></div>
          <input
            type="text"
            v-model="fapiao4"
            v-if="kai == 1"
          >
          <input
            type="text"
            v-if="kai == 0"
            readonly
          >
          </div>
        </div>
      </div>
      <div class="submit">
        <div
          class="submit-left"
          @click="toregistered()"
          style="margin-right:32px;"
          v-if="kai == 1"
        >
          提交
        </div>
        <div
          class="submit-left"
          style="color:#5E068C;background-color:#fff;"
          @click="toHaibao"
        >
          返回
        </div>
      </div>
    </div>
  </div>
  <div class="z_tishi">
    注：默认开具<span>电子发票</span>，开票内容为<span>信息咨询服务费</span>，如需纸质发票或有其它问题，请添加微信客服keyanpro。<br>开票一般需要3-4天时间，请您留意邮箱信息。
  </div>
  </div>
</template>

<script>
import { tomoney, getHsjuser ,invoice} from '../../../api/login/registered'
export default {
  data () {
    return {
      fapiao:"",
      fapiao1:"",
      fapiao2:"",
      fapiao3:"",
      fapiao4:"",
      fapiao1errmessage: '',
      fapiao2errmessage: '',
      fapiao3errmessage: '',
      kai:'',
      reason:'',
      money:''
    }
  },
  created(){
    this.user()
    this.navxuanzhong()
  },
  methods: {
     navxuanzhong(){
      localStorage.setItem("navActive", 0);
      this.$store.commit("setnavActive", 0);
    },
    user(){
      const that = this
      const params = {
        user_id: this.$store.state.userid,
      }
       getHsjuser(params).then(
        (res) => {
          that.fapiao = res.data.discipline;
          that.kai = res.data.kai
          that.reason = res.data.reason
          this.money = res.data.money
        },
        (err) => {

        }
      );
    },
    getfocus (event, str) {
      event.currentTarget.previousElementSibling.classList.add('gd')
      const that = this
      if (str === 'fapiao1') {
        that.$data.fapiao1eerrmessage = ''
      } else if (str === 'fapiao1') {
        that.$data.fapiao2eerrmessage = ''
      } else if (str === 'fapiao1') {
        that.$data.fapiao3eerrmessage = ''
      }
    },
    // 提交用户注册信息
    toregistered () {
      const that = this
      const params = {
        user_id: this.$store.state.userid,
        invoice_title: that.$data.fapiao1,
        code: that.$data.fapiao2,
        email: that.$data.fapiao3,
        notes: that.$data.fapiao4,
      }
      const fapiao1 = that.$data.fapiao1
      const fapiao2 = that.$data.fapiao2
      const fapiao3 = that.$data.fapiao3
      if (fapiao1 === '' || fapiao2 === '' || fapiao3 === '') { that.$message.error('请正确填写信息后重试'); return }
      invoice(params).then(res => {
        if (res.code === 1) {
          that.$message({
            message: '成功',
            type: 'success'
          })
        } else {
          that.$message.error(res.msg)
        }
      }, err => {
        console.log('失败', err)
      })
    },
    toHaibao: function () {
      this.$router.push({
        path: "homePage",
      });
    },
  }
}

</script>

<style lang="scss" scoped>
.max{background: #F1F2F6;
  box-sizing: border-box;
    padding-top: 82px;
  padding-bottom: 122px;
}
.gd {
  transform: translateY(-10px);
}
.login-main {
  margin: 0 auto;
  width: 1000px;
  height: 700px;
  background-color: #fff;
  display: flex;
  .login-left {
    width: 100%;
    box-sizing: border-box;
    padding: 59px 70px;
    .login-title {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      margin-bottom: 4px;
    }
    .henxian {
      width: 35px;
      height: 5px;
      background-color: #661392;
      margin-bottom: 105px;
    }
    .bufu{
      margin-top: -60px;margin-bottom: 60px;font-size: 20px;color: #444444;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #444444;
    }
    .form {
      width: 665px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .form-item {
        width: 292px;
        margin-bottom: 50px;
        label {
          position: relative;
          display: flex;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #b9b9b9;
          margin-bottom: 11px;
          .text {
            margin-right: 6px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
          }
          .redstar {
            color: #f00;
          }
          .errmessage {
            position: absolute;
            right: 0px;
            color: red;
          }
        }
        input {
          width: 100%;
          border: 0px;
          outline: 0px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #000000;
        }
        .sendconde {
          cursor: pointer;
          display: inline-block;
          float: right;
          color: #2a74fe;
          padding: 5px;
          background-color: #e6efff;
          transform: translateY(-6px);
          .count {
            display: inline-block;
            width: 70px;
            text-align: center;
            background-color: #e6efff;
          }
        }
        #code {
          width: 50%;
        }
      }
    }
    .submit {
      margin-top: 44px;
      display: flex;
      align-items: center;
      .submit-left {
        cursor: pointer;
        width: 160px;
        height: 60px;
        background: #5e068c;
        opacity: 1;
        border-radius: 4px;
        color: #fff;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #5e068c;
      }
      .submit-right {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 31px;
        color: #b9b9b9;
        span {
          cursor: pointer;
          color: #990263;
        }
      }
    }
  }
}

.z_input{width: 100%;
height: 56px;
border-radius: 2px 2px 2px 2px;
opacity: 1;
border: 1px solid #E8E8E8;
display: flex;align-items: center;
  .img{margin-left: 12px;margin-right: 22px;width: 32px;height: 100%;display: flex;align-items: center;
  justify-content: center;
    img{max-width: 100%;}
  }
}
.z_tishi{width: 1000px;clear: both;margin: 0 auto;font-size: 16px;color: #B4B4B4;line-height:1.5;padding-top:20px;
  span{color: #f00;font-weight: bold;}
}
</style>
